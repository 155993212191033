import React from 'react';
import * as styles from './CalculatorNew.module.scss';
import clsx from 'clsx';
import { numberWithSpaces } from '../../utils/number-with-spaces';

const Results = ({ results }) => {
  const { month_price: monthPrice, period_price: periodPrice } = results;

  return (
    <div className={styles.results}>
      <div className={clsx('h5', styles.resultsTitle)}>Результат расчета:</div>
      <div className={styles.resultsItems}>
        <div className={styles.resultsItem}>
          <div className={styles.resultsName}>
            Стоимость{' '}
            <span className={styles.resultsDesktopText}>электроэнергии</span> за
            1 месяц
          </div>
          <div className={styles.resultsValue}>
            {numberWithSpaces(Number(monthPrice))} ₽
          </div>
        </div>

        <div className={styles.resultsItem}>
          <div className={styles.resultsName}>
            Стоимость{' '}
            <span className={styles.resultsDesktopText}>электроэнергии</span> за{' '}
            {results.period} месяцев
          </div>
          <div className={styles.resultsValue}>
            {numberWithSpaces(Number(periodPrice))} ₽
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
