import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

const HotelThird = () => {
  return (
    <section id="terms" className="hotel-second hotel-section">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[03]</span>
                <span className="section-number__title">Условия</span>
              </div>
            </div>

            <div className="col-lg-9">
              <h2 className="section__title">Условия размещения</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 hotel-section__col">
              {/* TODO: probably svg here */}
              <StaticImage
                src="./assets/map.png"
                className="hotel-section__image"
                alt=""
              />
              <h4>Где мы находимся</h4>
              <div className="hotel-section__text">
                Наши мобильные ЦОДы находятся во Владимирской и Московской
                областях под надежной охраной. Мы раскрываем их точное
                местоположение только текущим клиентам
              </div>
            </div>

            <div className="col-lg-3 hotel-section__col">
              <StaticImage
                src="./assets/cameras.jpg"
                className="hotel-section__image"
                alt=""
              />
              <h4>Охрана объектов</h4>
              <div className="hotel-section__text">
                Все объекты находятся под круглосуточной охраной Росгвардии и
                ЧОП. Оснащены освещением, датчиками движения и КПП. Перекрестное
                видеонаблюдение покрывающее всю территорию.
              </div>
            </div>

            <div className="col-lg-3 hotel-section__col">
              <StaticImage
                src="./assets/server-room.jpg"
                className="hotel-section__image"
                alt=""
              />
              <h4>Надежная связь</h4>
              <div className="hotel-section__text">
                Непрерывность вашего дохода - наша прибыль, поэтому мы
                позаботились о двух каналах связи от двух разных поставщиков.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelThird;
