import * as styles from './CalculatorNew.module.scss';
import React from 'react';

const ModelSelect = ({ list, value, selectName, onChange, disabled }) => {
  return (
    <select
      className={styles.deviceSelect}
      onChange={onChange}
      disabled={disabled}
      value={value}
    >
      {disabled ? (
        <option value={value} defaultValue={value}>
          {selectName}
        </option>
      ) : (
        <>
          <option value="default" defaultValue="default">
            Устройство
          </option>
          {list.map(({ id, model }) => (
            <option key={id} value={id}>
              {model}
            </option>
          ))}
        </>
      )}
    </select>
  );
};

export { ModelSelect };
