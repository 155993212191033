import React, { useCallback, useContext, useRef } from 'react';
import { Link } from 'react-scroll';

import './Hero.scss';
import * as styles from './Hero.module.scss';

import { ModalContext } from '../../contex/modal-context';

import BlockLink from '../block-link/BlockLink';
import ArrowLeft from './assets/arrow-left.inline.svg';
import ArrowRight from './assets/arrow-right.inline.svg';

import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const HotelHero = () => {
  const { openModal } = useContext(ModalContext);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const openForm = () => {
    openModal({
      variant: 'callback',
    });
  };

  const ScrollLink = ({ to, children }) => (
    <Link
      to={to}
      smooth={true}
      duration={500}
      offset={-124}
      className={styles.hotelNavLink}
      activeClass={styles.hotelNavLinkActive}
      spy={true}
    >
      {children}
    </Link>
  );

  return (
    <>
      <div className={styles.hotelNav}>
        <div className="container">
          <div className={styles.hotelNavInner}>
            <ScrollLink to="calculator">Онлайн-калькулятор</ScrollLink>
            <ScrollLink to="advantages">Преимущества</ScrollLink>
            <ScrollLink to="terms">Условия</ScrollLink>
            <ScrollLink to="monitoring">Мониторинг</ScrollLink>
            <ScrollLink to="faq">F.A.Q.</ScrollLink>
            <ScrollLink to="feedback">Консультация</ScrollLink>
            <ScrollLink to="contacts">Контакты</ScrollLink>
          </div>
        </div>
      </div>

      <section className="hero">
        <div className="hero__wrapper">
          <div className="hero__bg  hero__bg--hotel" />
          <div className="container">
            <div className="hero__content">
              <Swiper
                ref={sliderRef}
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: true,
                }}
                modules={[Autoplay, Navigation, Pagination]}
                pagination={{ el: '.hero__pagination', clickable: true }}
              >
                <SwiperSlide>
                  <div className="hero__subtitle">
                    У нас есть все для достижения ваших бизнес-целей
                  </div>
                  <h1 className="hero__title">
                    Сопровождение на всех этапах майнинга
                  </h1>
                  <div className="hero__text">
                    Доставка, установка оборудования, настройка кошелька, выбор
                    оптимального пула, мониторинг, обслуживание, ремонт и
                    охрана. Мы предоставляем возможность покупки нового и Б/У
                    оборудования по лучшим ценам на рынке.
                  </div>
                  <button className="btn" onClick={openForm}>
                    Оставить заявку
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="hero__subtitle">Мы мобильны</div>
                  <h1 className="hero__title">Мобильный ЦОД</h1>
                  <div className="hero__text">
                    Мы любим страховать бизнес наших клиентов всеми доступными
                    методами.
                    <br />
                    Для обеспечения беспрерывной работы мы размещаем мощности
                    наших клиентов в передвижных контейнерах оснащенных всем
                    необходимым оборудованием для мониторинга.
                  </div>
                  <button className="btn" onClick={openForm}>
                    Оставить заявку
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="hero__subtitle">
                    Ваши деньги — наши заботы
                  </div>
                  <h1 className="hero__title">Свой обменник</h1>
                  <div className="hero__text">
                    Вывод криптовалюты может осуществляться прямо на ваш
                    кошелек. Но если вы не хотите заниматься утомительными
                    биржевыми операциями - мы можем конвертировать ваши средства
                    без комиссии по актуальному курсу binance, и перевести на
                    счет в любом удобном вам банке мира.
                  </div>
                  <button className="btn" onClick={openForm}>
                    Оставить заявку
                  </button>
                </SwiperSlide>
              </Swiper>

              <div className="hero__controls">
                <button onClick={handlePrev} className="hero__control-btn">
                  <ArrowLeft />
                </button>
                <div className="hero__pagination" />
                <button onClick={handleNext} className="hero__control-btn">
                  <ArrowRight />
                </button>
              </div>
            </div>

            <div className="hero__links">
              <BlockLink
                to="/catalog"
                title="Магазин"
                text="Наш каталог майнинг оборудования от ведущих производителей в мире"
                light
              />
              <BlockLink
                to="/"
                title="Cервисный центр"
                text="Ремонт любой сложности. Индивидуальный подход к каждому клиенту."
                light
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HotelHero;
