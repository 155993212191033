import React from 'react';

import './HotelFirst.scss';

import CardIcon from './assets/card.inline.svg';
import CashIcon from './assets/cash.inline.svg';
import UsdtIcon from './assets/usdt.inline.svg';
import BinanceIcon from './assets/binance.inline.svg';

import FixedPriceIcon from './assets/fixed-price.inline.svg';
import MonitoringIcon from './assets/monitoring.inline.svg';
import DiscountsIcon from './assets/discounts.inline.svg';
import RepairIcon from './assets/repair.inline.svg';
import AccreditationIcon from './assets/accreditation.inline.svg';
import CryptoIcon from './assets/crypto.inline.svg';
import CalculatorNew from '../../calculator-new/CalculatorNew';

const payments = [
  {
    Icon: CardIcon,
    text: 'Безналичный расчет по договору',
  },
  {
    Icon: CashIcon,
    text: 'Наличный расчет с физическими лицами',
  },
  {
    Icon: UsdtIcon,
    text: 'Возможность оплаты криптовалютой USDT',
  },
  {
    Icon: BinanceIcon,
    text: 'Добытыми активами по курсу Binance',
  },
];

const hotelFeatures = [
  { Icon: FixedPriceIcon, text: 'Фиксированная цена до конца года' },
  {
    Icon: MonitoringIcon,
    text: 'Мониторинг состояния вашего оборудования. Температурный контроль',
  },
  {
    Icon: DiscountsIcon,
    text: 'Скидки при падении стоимости добываемого актива',
  },
  {
    Icon: RepairIcon,
    text: 'Бесплатный ремонт на все время нашего сотрудничества',
  },
  {
    Icon: AccreditationIcon,
    text: 'Аккредитация для посещения ЦОДов при размещении от 500 кВтч',
  },
  {
    Icon: CryptoIcon,
    text: 'Возможность оплаты в криптовалюте после первого месяца',
  },
];

const HotelFirst = () => {
  return (
    <section id="calculator" className="hotel-first">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[01]</span>
                <span className="section-number__title">
                  Онлайн-калькулятор
                </span>
              </div>
            </div>

            <div className="col-lg-9">
              <h2 className="section__title">
                Расчет затрат на электроэнергию при&nbsp;использовании устройств
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <CalculatorNew />
            </div>

            <div className="col-lg-6">
              <div className="calc-features">
                <div className="calc-feature">
                  <div className="calc-feature__number">
                    <div className="calc-feature__value">
                      <span className="h1 calc-feature__value-title">4,3</span>
                      <span className="calc-feature__value-subtitle">
                        ₽/кВт⋅ч
                      </span>
                    </div>
                  </div>
                  <div className="calc-feature__content">
                    <div className="calc-feature__title h5">
                      Протестируйте весь спектр услуг по сниженной цене
                    </div>
                    <div className="calc-feature__text">
                      Первый месяц: 4,3 руб/кВт⋅ч
                      <br />
                      Второй месяц: 4,6 руб/кВт⋅ч
                      <br />
                      Третий месяц: 4,8 руб/кВт⋅ч
                    </div>
                  </div>
                </div>

                {/*<div className="calc-feature">*/}
                {/*  <div className="calc-feature__number">*/}
                {/*    <div className="calc-feature__value">*/}
                {/*      <span className="h1 calc-feature__value-title">1+1</span>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div className="calc-feature__content">*/}
                {/*    <div className="calc-feature__title h5">*/}
                {/*      Программа лояльности*/}
                {/*    </div>*/}
                {/*    <div className="calc-feature__text">*/}
                {/*      Если по вашей рекомендации мы получим нового клиента - вы*/}
                {/*      получите скидку до 50 копеек на кВт/ч на все время*/}
                {/*      обслуживания*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>

          <div className="payments">
            <div className="payments__text">
              <h3 className="payments__title">Способы оплаты услуг</h3>
              <div className="payments__subtitle">
                Забудьте о проблемах с расчетами. Оплачивайте&nbsp;услуги отеля
                удобным для вас способом.
              </div>
            </div>

            <div className="payments__list">
              {payments.map(({ Icon, text }) => (
                <div key={text} className="payment">
                  <div className="payment__image">{<Icon />}</div>
                  <div className="payment__text">{text}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="hotel-features">
            {hotelFeatures.map(({ Icon, text }) => (
              <div key={text} className="hotel-feature">
                <div className="hotel-feature__image">{<Icon />}</div>
                <div className="hotel-feature__text">{text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelFirst;
