import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';

import './HotelFifth.scss';

import { ModalContext } from '../../../contex/modal-context';
import { FaqItem, StaticFaq } from '../../faq/Faq';

const HotelFifth = () => {
  const [questionsTag, setQuestionsTag] = useState('all');
  const { openModal } = useContext(ModalContext);

  const openForm = () => {
    openModal({
      variant: 'callback',
    });
  };

  return (
    <section id="faq" className="hotel-fifth hotel-section">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[05]</span>
                <span className="section-number__title">FAQ</span>
              </div>
            </div>

            <div className="col-lg-9">
              <h2 className="section__title">Вы спрашиваете — мы отвечаем</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="faq-filters">
                <button
                  className={clsx(
                    'faq-filter',
                    questionsTag === 'all' && 'faq-filter--active',
                  )}
                  onClick={() => setQuestionsTag('all')}
                >
                  Все вопросы
                </button>
                <button
                  className={clsx(
                    'faq-filter',
                    questionsTag === 'sales' && 'faq-filter--active',
                  )}
                  onClick={() => setQuestionsTag('sales')}
                >
                  Покупка и продажа
                </button>
                <button
                  className={clsx(
                    'faq-filter',
                    questionsTag === 'equipment' && 'faq-filter--active',
                  )}
                  onClick={() => setQuestionsTag('equipment')}
                >
                  Оборудование и локация
                </button>
              </div>
            </div>

            <div className="col-lg-9">
              <StaticFaq>
                <FaqItem
                  staticItem
                  title="Как отслеживается работоспособность устройств?"
                  visible={
                    questionsTag === 'equipment' || questionsTag === 'all'
                  }
                >
                  Мы используем многофункциональную систему мониторинга
                  Prometheus и UI интерфейс для получения уведомлений и графиков
                  работы оборудования Grafana
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Как юридически оформляется размещение?"
                  visible={questionsTag === 'sales' || questionsTag === 'all'}
                >
                  Мы принимаем оборудование на основании договора возмездного
                  оказания услуг. К каждому договору составляются акты
                  приема-передачи оборудования
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Какое минимальное количество асиков для размещения?"
                  visible={questionsTag === 'sales' || questionsTag === 'all'}
                >
                  Мы принимаем от одной единицы майнингового оборудования, пока
                  это только асики, без ограничений по максимальному количеству
                  оборудования
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Как производится расчет стоимости ваших услуг?"
                  visible={questionsTag === 'sales' || questionsTag === 'all'}
                >
                  Мы суммируем потребленную электроэнергию вашим оборудованием,
                  умножаем на количество часов работы в пулле и умножаем на
                  текущую стоимость в рублях. Вы платите только за расход
                  электроэнергии по нашей ставке
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Хочу купить и разместить у вас оборудование"
                  visible={questionsTag === 'sales' || questionsTag === 'all'}
                >
                  <ol>
                    <li>
                      Вы выбираете в нашем <Link to="/catalog">магазине</Link>{' '}
                      нужную вам модель
                    </li>
                    <li>Оставляете заявку на приобретение</li>
                    <li>Наш менеджер связывается с вами в течении часа</li>
                    <li>Оплачиваете покупку</li>
                    <li>Мы получаем ваше оборудование.</li>
                    <li>Проверяем и тестируем.</li>
                    <li>
                      Вы приезжаете к нам в офис где мы подписываем акт
                      приема-передачи и договор на оказание услуг
                    </li>
                    <li>
                      Мы устанавливаем ваше оборудование в стенд, подбираем пулл
                      и подключаем к пуллу, настраиваем кошелек, подключаем вас
                      к системе мониторинга
                    </li>
                  </ol>
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Хочу разместить свое оборудование"
                  visible={questionsTag === 'sales' || questionsTag === 'all'}
                >
                  <ol>
                    <li>
                      <button onClick={openForm}>Свяжитесь</button> с нами любым
                      удобным для вас способом
                    </li>
                    <li>
                      Наш менеджер подберет для вас оптимальные условия
                      размещения
                    </li>
                    <li>
                      Мы можем произвести вывоз оборудования из любой удобной
                      для вас локации в пределах Московской или Владимирской
                      области. Либо вы предоставляете ваше оборудование
                      самостоятельно.
                    </li>
                    <li>
                      Вы приезжаете к нам в офис где мы подписываем акт
                      приема-передачи и договор на оказание услуг
                    </li>
                    <li>
                      Мы получаем ваше оборудование, проверяем, тестируем,
                      устанавливаем в стенд, подбираем пулл и подключаем к
                      пуллу, настраиваем кошелек
                    </li>
                  </ol>
                </FaqItem>
                <FaqItem
                  staticItem
                  title="В какие сроки я должен оплатить счет на услуги размещения?"
                  visible={questionsTag === 'sales' || questionsTag === 'all'}
                >
                  Предоплата осуществляется только в первый месяц размещения.
                  Далее 1го числа каждого следующего месяца
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Я могу купить разместить у вас оборудование не посещая офис?"
                  visible={questionsTag === 'sales' || questionsTag === 'all'}
                >
                  Мы можем подписать договор на вашей территории или удаленно и
                  вывезти оборудование самостоятельно. Если вы хотите разместить
                  оборудование из другого отеля - мы можем подписать договор и
                  акт удаленно и вывезти ваше оборудование самостоятельно
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Сколько свободных мест?"
                  visible={
                    questionsTag === 'equipment' || questionsTag === 'all'
                  }
                >
                  Мы постоянно поддерживаем свободный резерв на площадках в
                  размере 50-70 устройств, то есть 250 кВт свободной мощности
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Могу ли я визуально следить за своим устройством?"
                  visible={
                    questionsTag === 'equipment' || questionsTag === 'all'
                  }
                >
                  На каждой из наших площадок установлено по 12 камер
                  наблюдения. Если вы хотите следить за вашим оборудованием
                  внутри контейнера мы установим персональную камеру и передадим
                  вам доступ к круглосуточной трансляции
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Если мое оборудование сломается у вас?"
                  visible={
                    questionsTag === 'equipment' || questionsTag === 'all'
                  }
                >
                  Мы даем гарантию на все модели майнингового оборудования
                  представленного в нашем магазине. В том числе и Б/У. Если
                  поломка произошла в устройстве без гарантии - ремонт связанный
                  с косами и кулерами мы проведем бесплатно. Стоимость других
                  видов ремонта вы можете{' '}
                  <button onClick={openForm}>уточнить</button> у нашего
                  менеджера
                </FaqItem>
                <FaqItem
                  staticItem
                  title="Какой режим работы дата-центра?"
                  visible={
                    questionsTag === 'equipment' || questionsTag === 'all'
                  }
                >
                  ЦОДы функционируют круглосуточно. Если вы размещаете у нас
                  более 500 кВтч - можете посетить нас в рабочее время с 10:00
                  до 18:00
                </FaqItem>
              </StaticFaq>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelFifth;
