// extracted by mini-css-extract-plugin
export var addMore = "CalculatorNew-module--addMore--IAE18";
export var addNew = "CalculatorNew-module--addNew--eq+DG";
export var calc = "CalculatorNew-module--calc--uIZ45";
export var device = "CalculatorNew-module--device--EN+wQ";
export var deviceDelete = "CalculatorNew-module--deviceDelete--3Et1S";
export var deviceInput = "CalculatorNew-module--deviceInput--RALtn";
export var deviceNum = "CalculatorNew-module--deviceNum--DZXZ1";
export var deviceNumButton = "CalculatorNew-module--deviceNumButton--uF5f9";
export var deviceNumInput = "CalculatorNew-module--deviceNumInput--IWeoM";
export var deviceSelect = "CalculatorNew-module--deviceSelect--fCLa1";
export var devicesList = "CalculatorNew-module--devicesList--hX8D-";
export var input = "CalculatorNew-module--input--eav8d";
export var inputGrid = "CalculatorNew-module--inputGrid--GAetT";
export var inputGroup = "CalculatorNew-module--inputGroup--oVvrZ";
export var inputGroupText = "CalculatorNew-module--inputGroupText--+EZ7g";
export var label = "CalculatorNew-module--label--CU8cy";
export var placeholderText = "CalculatorNew-module--placeholderText--ooSQ9";
export var results = "CalculatorNew-module--results---MFLz";
export var resultsDesktopText = "CalculatorNew-module--resultsDesktopText--9SQm+";
export var resultsItem = "CalculatorNew-module--resultsItem--D8K+C";
export var resultsItems = "CalculatorNew-module--resultsItems--csB1i";
export var resultsName = "CalculatorNew-module--resultsName--oEffc";
export var resultsTitle = "CalculatorNew-module--resultsTitle--uka5G";
export var resultsValue = "CalculatorNew-module--resultsValue--qvzRW";