import React from 'react';

import './HotelSixth.scss';

import HotelCallback from '../callback/HotelCallback';

const HotelSixth = () => {
  return (
    <section id="feedback" className="hotel-callback">
      <div className="container">
        <div className="hotel-callback__wrapper">
          <div className="row">
            <div className="col-lg-6">
              <div className="hotel-callback__text">
                <div className="section-number">
                  <span className="section-number__number">[06]</span>
                  <span className="section-number__title">Консультация</span>
                </div>
                <h2 className="section__title">
                  Не нашли ответа на свой вопрос? Мы здесь, чтобы помочь вам.
                </h2>
              </div>
            </div>

            <div className="col-lg-5 offset-lg-1">
              <HotelCallback />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelSixth;
