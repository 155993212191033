import React from 'react';
import * as styles from './CalculatorNew.module.scss';

import debounce from 'lodash.debounce';
import cx from 'classnames';

import { Field, Form, Formik, useFormikContext } from 'formik';

const DebouncedSubmit = ({ debounceMs }) => {
  const formik = useFormikContext();
  const debouncedSubmit = React.useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [debounceMs, formik.submitForm],
  );

  React.useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);
};

const CalculatorFields = ({ fields, power, period, price, valuesSubmit }) => {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        power,
        period,
        price,
      }}
      onSubmit={(values) => valuesSubmit(values)}
    >
      {({ errors, touched }) => (
        <Form>
          <DebouncedSubmit debounceMs={300} />

          <div className={styles.inputGrid}>
            {fields.map((field) => (
              <div
                key={field.name}
                className={cx(
                  'input-group',
                  'input-group--white',
                  errors[field.name] &&
                    touched[field.name] &&
                    'input-group--error',
                )}
              >
                <label className="form-label">{field.label}</label>
                <div className="form-control-group" data-label={field.helper}>
                  <Field
                    name={field.name}
                    type="number"
                    className="form-control"
                  />
                </div>

                {errors[field.name] && touched[field.name] ? (
                  <div className="invalid-feedback">{errors[field.name]}</div>
                ) : null}
              </div>
            ))}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CalculatorFields;
