export async function fetchDevicesList() {
  const response = await fetch('https://api.chivoz.ru/hotel/miners');
  return await response.json();
}

export async function fetchCalculatorData() {
  const response = await fetch('https://api.chivoz.ru/hotel/show', {
    method: 'GET',
    credentials: 'include',
  });
  return await response.json();
}

export async function hotelCalculate(values) {
  const response = await fetch('https://api.chivoz.ru/hotel/calculate', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  return await response.json();
}

export async function addDevice(productId, qty = 1) {
  console.log('productId', productId, 'qty', qty);
  const response = await fetch(
    `https://api.chivoz.ru/hotel/add/${productId}/${qty}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  return await response.json();
}

export async function setDeviceQty(productId, qty) {
  console.log('productId', productId, 'qty', qty);
  const response = await fetch(
    `https://api.chivoz.ru/hotel/set/${productId}/${qty}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  return await response.json();
}

export async function removeDevice(productId) {
  const response = await fetch(
    `https://api.chivoz.ru/hotel/remove/${productId}`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  return await response.json();
}

export async function clearHotelDevices() {
  const response = await fetch(`https://api.chivoz.ru/hotel/clear`, {
    method: 'GET',
    credentials: 'include',
  });
  return await response.json();
}
