import * as React from 'react';

import '../components/hotel/Hotel.scss';

import Layout from '../components/layout/Layout';
import Seo from '../components/seo/Seo';
import HotelHero from '../components/hero/HotelHero';
import HotelFirst from '../components/hotel/first/HotelFirst';
import HotelSecond from '../components/hotel/second/HotelSecond';
import HotelThird from '../components/hotel/third/HotelThird';
import HotelFourth from '../components/hotel/fourth/HotelFourth';
import HotelFifth from '../components/hotel/fifth/HotelFifth';
import HotelSixth from '../components/hotel/sixth/HotelSixth';
import Seventh from '../components/home/seventh/Seventh';

const HotelPage = () => {
  return (
    <Layout>
      <Seo
        title="Чиним Возим"
        description="Быстро и качественно ремонтируем асик-майнеры и блоки питания с гарантией на работу точно в срок"
      />

      <HotelHero />
      <HotelFirst />
      <HotelSecond />
      <HotelThird />
      <HotelFourth />
      <HotelFifth />
      <HotelSixth />

      <Seventh />
    </Layout>
  );
};

export default HotelPage;
