import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

const HotelFourth = () => {
  return (
    <section id="monitoring" className="hotel-second hotel-section">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[04]</span>
                <span className="section-number__title">Мониторинг</span>
              </div>
            </div>

            <div className="col-lg-9">
              <h2 className="section__title">
                Мониторинг, контроль качества и&nbsp;гарантийное обслуживание
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 hotel-section__col">
              {/* TODO: probably svg here */}
              <StaticImage
                src="./assets/control.png"
                className="hotel-section__image"
                alt=""
              />
              <h4>Контроль</h4>
              <div className="hotel-section__text">
                При покупке или установке оборудования вы получаете полный
                отчет: фото счетчика, фото размещения и удаленный доступ к 12
                камерам наблюдения и системе мониторинга
              </div>
            </div>

            <div className="col-lg-3 hotel-section__col">
              <StaticImage
                src="./assets/qa.jpg"
                className="hotel-section__image"
                alt=""
              />
              <h4>Тестирование устройств</h4>
              <div className="hotel-section__text">
                При покупке оборудования мы проводим двух-факторную проверку. У
                поставщика и непосредственно при установке.
              </div>
            </div>

            <div className="col-lg-3 hotel-section__col">
              <StaticImage
                src="./assets/guarantee.jpg"
                className="hotel-section__image"
                alt=""
              />
              <h4>Гарантия</h4>
              <div className="hotel-section__text">
                Мы предоставляем гарантию на новое и Б/У оборудование. Так же вы
                получаете гарантию от поставщика от одного года.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelFourth;
