import React, { useContext, useEffect, useState } from 'react';
import * as styles from './CalculatorNew.module.scss';

import { ModalContext } from '../../contex/modal-context';

import {
  addDevice,
  clearHotelDevices,
  fetchDevicesList,
  hotelCalculate,
  removeDevice,
  setDeviceQty,
} from './api';
import Results from './Results';
import { Device } from './Device';
import CalculatorFields from './CalculatorFields';

import AddIcon from './assets/add.inline.svg';

const CalculatorNew = () => {
  const { openModal } = useContext(ModalContext);

  const [listCounter, setListCounter] = useState(0);
  const [results, setResults] = useState(null);
  const [devices, setDevices] = useState([]);
  const [devicesList, setDevicesList] = useState([]);

  const [power, setPower] = useState(0);
  const [period, setPeriod] = useState(0);
  const [price, setPrice] = useState(0);

  // TODO: add products restoration
  useEffect(() => {
    clearHotelDevices().then(() => {
      fetchDevicesList().then((data) => {
        setDevicesList(data);
      });
    });
  }, []);

  const addEmptyDevice = () => {
    const newDevice = {
      id: listCounter + 1,
      qty: 1,
    };

    setListCounter(listCounter + 1);
    setDevices([...devices, newDevice]);
  };

  const onAddDeviceHandler = (listItemId, selectedProductId, qty) => {
    addDevice(selectedProductId, qty).then((response) => {
      const { current_power: power, period, price } = response;
      calculateBill({
        power,
        period,
        price,
      });
    });
    setProductId(listItemId, selectedProductId);
  };

  const setProductId = (listItemId, productId) => {
    const updateList = devices.map((device) => {
      if (device.id === listItemId) {
        device.productId = Number(productId);
        return device;
      } else {
        return device;
      }
    });

    setDevices(updateList);
  };

  const updateQty = (id, qty) => {
    const updateList = devices.map((device) => {
      if (device.id === id) {
        device.qty = qty;
        if (device.productId) {
          setDeviceQty(device.productId, qty).then((response) => {
            const { current_power: power, period, price } = response;
            calculateBill({
              power,
              period,
              price,
            });
          });
        }

        return device;
      } else {
        return device;
      }
    });

    setDevices(updateList);
  };

  const deleteDevice = (id) => {
    const updatedList = devices.filter((device) => {
      if (device.id === id) {
        if (device.productId) {
          removeDevice(device.productId).then((response) => {
            const { current_power: power, period, price } = response;
            calculateBill({
              power,
              period,
              price,
            });
          });
        }
      }
      return device.id !== id;
    });
    setDevices(updatedList);
  };

  const calculateBill = ({ power, period, price }) => {
    if (power <= 0 || period <= 0 || price <= 0) {
      setResults(null);
      setPower(power);
      setPeriod(period);
      setPrice(price);
    }

    if (power > 0 && period > 0 && price > 0) {
      hotelCalculate({ power, period, price }).then((response) => {
        const { current_power: power, period, price } = response;

        setResults(response);
        setPower(power);
        setPeriod(period);
        setPrice(price);
      });
    }
  };

  const openForm = () => {
    openModal({
      variant: 'callback',
    });
  };

  const fields = [
    {
      label: 'Потребление электроэнергии',
      name: 'power',
      value: power,
      method: setPower,
      helper: 'кВт⋅ч',
    },
    {
      label: 'Срок использования',
      name: 'period',
      value: period,
      method: setPeriod,
      helper: 'месяцев',
    },
    {
      label: 'Стоимости электроэнергии',
      name: 'price',
      value: price,
      method: setPrice,
      helper: '₽',
    },
  ];

  const selectedProductIds = devices.map((device) => device.productId);
  const filteredDevicesList = devicesList.filter(
    (device) => !selectedProductIds.includes(device.id),
  );

  return (
    <div>
      <div className={styles.calc}>
        <div className="h4">Онлайн-калькулятор электроэнергии</div>

        {devices?.length > 0 ? (
          <>
            <div className={styles.label}>Выберите устройство</div>

            <div className={styles.devicesList}>
              {devices.map(({ id, qty }) => (
                <Device
                  key={id}
                  listItemId={id}
                  list={filteredDevicesList}
                  qty={qty}
                  deleteDevice={deleteDevice}
                  updateQty={updateQty}
                  onDeviceQtySet={setDeviceQty}
                  onAddDeviceHandler={onAddDeviceHandler}
                />
              ))}
            </div>
          </>
        ) : (
          <button className={styles.addNew} onClick={addEmptyDevice}>
            Добавить устройство
          </button>
        )}

        {devices?.length > 0 && (
          <button className={styles.addMore} onClick={addEmptyDevice}>
            <AddIcon />
            Добавить еще устройство
          </button>
        )}

        <CalculatorFields
          fields={fields}
          power={power}
          period={period}
          price={price}
          valuesSubmit={calculateBill}
        />

        {results ? (
          <Results results={results} />
        ) : (
          <p className={styles.placeholderText}>
            Добавьте устройство или введите данные, чтобы сделать расчет
            электроэнергии{' '}
          </p>
        )}
      </div>

      {results && (
        <button className="btn btn--full" onClick={openForm}>
          хочу обсудить предложение
        </button>
      )}
    </div>
  );
};

export default CalculatorNew;
