import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import { ModalContext } from '../../../contex/modal-context';

import * as Yup from 'yup';
import cx from 'classnames';
import MaskedInput from 'react-text-mask';

const phoneNumberMask = [
  '+',
  '7',
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
const phoneRegExp = /(?:\+?)[78]+[0-9() -]{16,17}/;

const ValidationScheme = Yup.object({
  name: Yup.string().min(2, 'Минимум 2 символа').required('Обязательное поле'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Введён неверный номер телефона')
    .required('Обязательное поле'),
  email: Yup.string().email('Введён неверный email'),
  message: Yup.string(),
});

const HotelCallback = () => {
  const { openModal, closeModal } = useContext(ModalContext);

  const onPhoneFocus = (e) => {
    e.target.selectionStart = 4;
    e.target.selectionEnd = 4;
  };

  const submitForm = (values, { setSubmitting }) => {
    console.log(values);
    fetch('https://api.chivoz.ru/form/send', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then((data) => {
        console.log(data);

        setSubmitting(false);
        closeModal();
        openModal({
          variant: 'success',
        });
      })
      .catch((error) => {
        console.log(error);

        setSubmitting(false);
        closeModal();
        openModal({
          variant: 'error',
        });
      });
  };

  return (
    <Formik
      initialValues={{
        name: '',
        phone: '+7',
        email: '',
        message: '',
      }}
      validationSchema={ValidationScheme}
      onSubmit={submitForm}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <div
            className={cx(
              'input-group',
              errors.name && touched.name && 'input-group--error',
            )}
          >
            <label htmlFor="callback-name" className="form-label">
              Ваше имя*
            </label>
            <Field
              name="name"
              className="form-control"
              placeholder="Александр"
            />

            {errors.name && touched.name ? (
              <div className="invalid-feedback">{errors.name}</div>
            ) : null}
          </div>

          <div
            className={cx(
              'input-group',
              errors.phone && touched.phone && 'input-group--error',
            )}
          >
            <label htmlFor="callback-phone" className="form-label">
              Мобильный телефон*
            </label>

            <Field name="phone">
              {({ field }) => (
                <MaskedInput
                  {...field}
                  mask={phoneNumberMask}
                  id="callback-phone"
                  placeholder="+7 (000) 123-45-67"
                  type="text"
                  className={cx(
                    'form-control',
                    errors.phone && touched.phone
                      ? 'text-input error'
                      : 'text-input',
                  )}
                  guide={false}
                  onFocus={onPhoneFocus}
                />
              )}
            </Field>

            {errors.phone && touched.phone ? (
              <div className="invalid-feedback">{errors.phone}</div>
            ) : null}
          </div>

          <div
            className={cx(
              'input-group',
              errors.email && touched.email && 'input-group--error',
            )}
          >
            <label htmlFor="callback-email" className="form-label">
              Электронная почта
            </label>

            <Field
              id="callback-email"
              name="email"
              type="email"
              className="form-control"
              placeholder="example@yandex.ru"
            />
            {errors.email && touched.email ? (
              <div className="invalid-feedback">{errors.email}</div>
            ) : null}
          </div>

          <div
            className={cx(
              'input-group',
              errors.message && touched.message && 'input-group--error',
            )}
          >
            <Field
              as="textarea"
              name="message"
              className="form-control"
              placeholder="Текст сообщения"
            />
            {errors.message && touched.message ? (
              <div className="invalid-feedback">{errors.message}</div>
            ) : null}
          </div>

          <div className="modal__action">
            <button
              type="submit"
              className="btn btn--full"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Идет отправка...' : 'Получить консультацию'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default HotelCallback;
