import React from 'react';

import './HotelSecond.scss';

import { StaticImage } from 'gatsby-plugin-image';

import Rosseti from './assets/rosseti.inline.svg';
import Tns from './assets/tns.inline.svg';
import Energo from './assets/energo.inline.svg';
import RusHydro from './assets/rus-hydro.inline.svg';

const HotelSecond = () => {
  return (
    <section id="advantages" className="hotel-second hotel-section">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[02]</span>
                <span className="section-number__title">Преимущества</span>
              </div>
            </div>

            <div className="col-lg-9">
              <h2 className="section__title">Бесперебойная энергия</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 hotel-section__col">
              <StaticImage
                src="./assets/power.jpg"
                className="hotel-section__image"
                alt=""
              />
              <h4>Обеспечение бесперебойного электроснабжения потребителей</h4>
              <div className="hotel-section__text">
                Мы гарантируем фактический uptime 98%. Используем промышленные
                ИБП. Даже при сбое в электросети мы сможем развернуть наши ГПУ и
                обеспечить мощность до 10 мгВт в течении 36 часов. Ваше
                оборудование будет максимально эффективным, так как у нас
                исключены отключения при пиковых нагрузках в сети
              </div>
            </div>

            <div className="col-lg-6 hotel-section__col">
              <div className="hotel-second__companies">
                <div className="hotel-second__companies-grid">
                  <div className="hotel-second__companies-item">
                    <Rosseti />
                  </div>
                  <div className="hotel-second__companies-item">
                    <Tns />
                  </div>
                  <div className="hotel-second__companies-item">
                    <Energo />
                  </div>
                  <div className="hotel-second__companies-item">
                    <RusHydro />
                  </div>
                </div>
              </div>

              <h4>Наши энергетические партнеры</h4>
              <div className="hotel-section__text">
                Нами был сделан выбор в пользу крупнейших игроков рынка регионов
                Московской и Владимирской областей, которые зарекомендовали себя
                как олицетворение надежности на рынке электроэнергетики. Мы
                определяем актуальную цену на наши услуги исходя из текущей
                тарификации поставщика. Если тарификация поставщика изменится -
                вы узнаете об этом первыми из официального письма компании. При
                изменении тарифов поставщиком, мы не меняем маржинальность нашей
                бизнес-модели, то есть конечная цена за кВтч изменится ровно на
                столько, на сколько поднял ее поставщик.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelSecond;
