import React, { useState } from 'react';
import * as styles from './CalculatorNew.module.scss';

import { ModelSelect } from './ModelSelect';

import DeleteIcon from './assets/delete.inline.svg';
import MinusIcon from './assets/minus.inline.svg';
import PlusIcon from './assets/plus.inline.svg';

const Device = ({
  listItemId,
  list,
  qty,
  updateQty,
  onAddDeviceHandler,
  deleteDevice,
}) => {
  const [isChanged, setIsChanged] = useState(false);
  const [selectValue, setSelectValue] = useState('default');
  const [selectName, setSelectName] = useState('Устройство');

  const onChangeHandler = (e) => {
    const selectedDeviceId = e.target.value;
    const selectedDeviceName = e.target.selectedOptions[0].label;
    setSelectValue(selectedDeviceId);
    setSelectName(selectedDeviceName);

    onAddDeviceHandler(listItemId, selectedDeviceId, qty);

    setIsChanged(true);
  };

  return (
    <div className={styles.device}>
      <div className={styles.deviceInput}>
        <ModelSelect
          list={list}
          value={selectValue}
          onChange={onChangeHandler}
          selectName={selectName}
          disabled={isChanged}
        />

        <button
          className={styles.deviceDelete}
          onClick={() => deleteDevice(listItemId)}
        >
          <DeleteIcon />
        </button>
      </div>
      <div className={styles.deviceNum}>
        <button
          className={styles.deviceNumButton}
          onClick={() => updateQty(listItemId, qty > 1 ? qty - 1 : qty)}
          disabled={qty === 1}
        >
          <MinusIcon />
        </button>
        <input
          type="text"
          value={qty}
          className={styles.deviceNumInput}
          readOnly
        />
        <button
          className={styles.deviceNumButton}
          onClick={() => updateQty(listItemId, qty + 1)}
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  );
};

export { Device };
